@import "variable";
@import "global";

.ems-multiselect-dropdown {
  .p-chkbox {
    .p-chkbox-box {
      @include border-radius(0px);

      &.p-highlight {
        border-color: $button-border !important;
        background-color: $button-border !important;
      }
    }
  }

  .p-multiselect-panel {
    @include border-radius(0px);

    .p-state-highlight {
      color: $subnav-heading !important;
      background: $dropdown-data-color !important;
    }
  }

  .p-multiselect {
    height: 42px;
    width: 220px;
    @include border-radius(0px);

    .p-multiselect-label {
      margin-top: 5px;
    }
  }

  .p-float-label {
    & > label {
      display: none;
    }
  }
}

.page-title {
  font-weight: normal;
  color: $subnav-heading;
  font-size: 24px;
}

.buttons {
  padding: 11px 20px;
  background: $white;
  border: 1px solid $button-border;
  color: $button-border;
  font-size: 16px;
  cursor: pointer;

  &[disabled],
  &[disabled].button-save {
    border: 1px solid $disabled-color;
    cursor: not-allowed;
    color: $disabled-color !important;
    background: none !important;
  }

  &.button-save {
    color: $white !important;
    background: $button-border !important;
    .pi-plus {
      &:before  {
        -webkit-text-stroke: 2px;
        font-size: 12px;
      }
    }
  }

  &.button-without-background {
    border : none;
    background: none;
    padding: 8px 0px;
  }

  &.button-search {
    color: $white !important;
    background: $button-border !important;
    font-size: 18px;
    padding: 11px 16px;
  }

  &[disabled],
  &[disabled].button-search {
    border: 1px solid $disabled-color;
    cursor: not-allowed;
    color: $disabled-color !important;
    background: none !important;
  }

  &:not(:last-child) {
    margin: 0 10px 0 0;
  }

  i {
    margin: 0 5px 0 0;
  }

  &.button-default {
    background: transparent !important;
    color: $button-border;
  }

  &.button-default-cancel {
    background: $button-border !important;
    color: $white !important;
  }

  &.activate-btn {
    background: $activate-button-color !important;
    border: 1px solid $activate-button-color;
  }

  &.reactivate-btn {
    background: $activate-button-color !important;
    border: 1px solid $activate-button-color;
    margin-left: 10px;
  }

  &.deactivate-btn {
    background: $error-field-border !important;
    border: 1px solid $error-field-border;
  }

  &.button-filter {
    padding: 11px 10px;
  }

  &.navigation-button {
    background: #3C4B56;
    border: none;
    color: white;
    padding: 0;
    font-size: 15px
  }
}


.dropdown-check-list {
  position: relative;

  .anchor {
    cursor: pointer;
    padding: 10px 6px 10px 10px;
    border: 1px solid #ccc;
  }

  .items {
    border: 1px solid #00A0DF;
    z-index: 9;
    position: absolute;
    background-color: white;
    width: 100%;
    list-style: none;
  }

  .security-label {
    display: block;
  }

  .dropdown-list {
    height: 31px;
    line-height: 30px;
    cursor: pointer;
    padding: 0 10px;

    &:hover,
    &:active {
      background-color: #E6F3F8;
    }

    .emtr-checkbox {
      float: right;
    }
  }

  .security-selected-values {
    font-weight: bold;

    .security-category-space {
      display: inline-block;
      padding: 0 0 0 3px;
    }
  }

  .security-select {
    position: absolute;
    top: -3px;
    padding: 0 0 0 2px;
    font-size: 10px;
  }

  .highlight-security {
    background-color: #E6F3F8;
  }

  .anchorDisabled {
    color: #98A4AE;
  }
}


/*for floating label and input placeholder*/
.p-float-label > label,
input::placeholder {
  color: $black;
}

.p-float-label input[disabled] + label {
  color: $black !important;
  opacity: 1 !important;
}

.phonenumber.p-float-label {
  .ng-dirty + label {
    color: inherit;
  }
  input {
      &::placeholder {
          opacity: 1;
      }
  }
}

.error-list {
  margin: 0 0 0 15px;
}


.link {
  color: $button-border;
  text-decoration: underline;
}

//character Limit
.character-limit {
  color: $error-field-border;
}

/*OVERRIDE NAVIGATION OF SDPS (FUSION HORIZONTAL NAVIGATION)*/


//colorpicker
.rule-name-display {
  .ui-colorpicker-overlay {
    left: 50%;
    transform: translateX(-50%);
    z-index: 9999;
  }

  .ui-colorpicker-overlay-panel {
    left: 50px !important;
  }
}

/*security description and notes*/


.auto-complete-list {
  .p-autocomplete {
    width: 100%;

    .p-inputtext {
      text-indent: 35px;
      width: 100%;
      font-family: $font-family-regular;
      @include border-radius(0px);
      font-size: $font-normal;
      padding: 10px;
      border: 1px solid $border-color;
      &:enabled:hover:not(.p-state-error) {
        border-color: $border-color;
      }
    }
  } 

    &.invalidSearchBox {
        .p-inputtext {
          border: 1px solid $error-field-border !important;
        }
    }

  .p-autocomplete-panel {
    max-height: none !important;
    min-width: 0% !important;
    width: 100% !important;
    @include border-radius(0px);
  }

  ul {
    li {
      font-size: $font-normal;

      .search-symbol {

        text-align: left;
        display: inline-block;
        width: 40%;
        vertical-align: top;

        &.truncate-ellipses {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 40%;
        }
      }

      .search-desc {
        text-align: right;
        display: inline-block;
        width: 60%;
        vertical-align: top;

        &.truncate-ellipses {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 60%;
        }
      }
    }
  }
}

/*Custom Class for Multiselect Dropdown*/
.entire-search-security {
  .dropdown-check-list {
    .items {
      width: calc(100% - 16px) !important;
    }
  }
}


.step-action-buttons {
  .buttons:not(:last-child) {
    margin: 0 10px 0 0;
  }
}

.emtr-radio {
  .radio-button {
    width: auto !important;
  }

  .p-radiobutton {
    height: auto !important;
    display: inline-block;

    .p-radiobutton-box {
      border: 2px solid $textarea-label-color;
      width: 22px;
      height: 22px;

      &:not(.p-disabled):not(.p-highlight):hover {
        border-color: #98A4AE;
      }

      &.p-highlight {
        border-color: #037DAE;
        background-color: #fff !important;

        .p-radiobutton-icon {
          background-color: #037DAE;
          width: 10px;
          height: 10px;
        }

        &:not(.p-disabled):hover {
          background-color: #fff;
          border-color: #037DAE;
        }
      }

      &.p-focus {
        box-shadow: none;
      }
    }
  }

  .p-radiobutton-label {
    cursor: pointer !important;
    display: inline-block;
    vertical-align: middle;
    line-height: 1.5;
    margin: 0 0 0 0.5em;
  }
}

.emtr-dropdown {
  &.group-plan-name-dropdown, &.instrument-group-name-dropdown {
    .p-dropdown {
      // &.p-corner-all {
        border-radius: 0;
        max-width: 50px !important;
      // }
      .p-dropdown-label { 
        text-overflow: inherit;
      }
    }
  }
  &.access-type-dropdown {
    .p-dropdown {
      &.p-corner-all {
        border-radius: 0;
        min-width: 183px !important;
      }
    }
  }

  .p-dropdown {
    &.p-corner-all {
      border-radius: 0;
    }
  }

  &.sdps-dropdown {
    vertical-align: middle;
    display: inline-block;
    padding-left: 10px;
    @include border-radius(0px);
  }

  .sdps-dropdown--lightGrey__select {
    background: $white;
    border: 1px solid $footer-bg;
  }

  .sdps-dropdown__icon {
    top: 55%;
    transform: translate(-90%, -50%);
    left: 80%;
    right: 6px;
  }

  .sdps-dropdown--lightGrey__select:hover {
    background: $white;
    border: 1px solid $footer-bg;
  }

  .sdps-dropdown__select:hover {
    border: 1px solid $footer-bg;
  }

  .sdps-dropdown__select {
    @include border-radius(0px);
    height: 40px;
    min-width: 90px;
  }

  .sch-caret-down {
    font-size: 20px;
  }
}

//toast
.toastController {
  .p-toast {
    position: inherit;
    width: 100%;

    .p-toast-close-icon {
      top: 0.9rem;
      right: 1rem;
      z-index: 1;

    }

    .p-toast-message {
      background-color: transparent !important;
      box-shadow: none;
      margin: 0;
    }

    .p-toast-message-content {
      padding: 0;

      .sdps-notification__text {
        margin: auto;
        margin-left: 10px;
      }

      .sdps-notification--confirmation {
        width: 100%;
        background-color: #f0f9f0;
        border: thin solid #addcad;
        .sdps-notification__icon {
          color: #009200;
          .sdps-icon{
            width: 30px;
          }
        }
      }

      .sdps-notification--warning {
        background-color: #fefaee;
        border: thin solid #f7df8c;
        width: 100%;
        .sdps-notification__icon {
          color: #edb700;
          .sdps-icon{
            width: 30px;
          }
        }
      }
      .sdps-notification--information {
        width: 100%;
        margin-top: 0;
      }
      .sdps-notification__icon {
        margin: auto;
      }

      .p-toast-icon-close {
        color: #212121;
        top: 0.4rem;
        right: 1rem;
        position: absolute;
        .p-toast-icon-close-icon.pi-times {
          &::before {
            -webkit-text-stroke: 2px;
            font-size: 0.8rem;
          }
        }
        &:focus {
          box-shadow: none;
        }

      }
    }
  }
}
.content-area{
  .sdps-notification__text {
    margin: auto;
    margin-left: 10px;
  }
  .sdps-notification--error{
    background-color: #fcf7f9;
    border: thin solid #e5b2c1;
    width: 100%;
    top: -15px;
    .sdps-notification__icon {
      color: #aa0033;
    padding-left: 0.5rem !important;
     padding-top: 0.25rem !important;
     .sdps-icon{
      width: 30px;
    }
  }
  }  

  .sdps-notification--information{
    background-color: #edf3f9;
    border: thin solid #9ecde0;
    .sdps-notification__icon {
      color: #037DAE;
      padding-left: 0.5rem !important;
      padding-top: 0.25rem !important;
      .sdps-icon{
        width: 30px;
      }
  }
  }
}


table {
  th.table-checkbox-selection {
    width: 40px;
  }
  .filter-icon {
    height: 54px;
  }
  th.filter-action{
    width: 160px;
  }
  td.table-checkbox-selection {
    width: 42px;
  }

  td.no-right-left-padding, th.no-right-left-padding {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

.disabled-edit-button {
  .pi {
    font-size: 1em;
  }

  &.sdps-button {
    border: 1px solid $table-record-border !important;
    background: $white !important;
    color: $border-color !important;
    width: 30px;
    height: 30px;
    padding: 4px 3px;
    align-items: center;
    justify-content: center;
    display: flex;
    @include border-radius(0px);
  }
}

.disabled-button-icon {
  .edit-button {
    &.sdps-button {
      border: 1px solid $table-record-border !important;
      color: $border-color !important;
    }

    &:hover {
      background-color: $white !important;
      color: $border-color !important;
    }
  }
}

.sdps-modal__dialog--flex--large, .sdps-modal__dialog--large {
  .sch-thin-x {
    position: absolute;
    right: -15px;
    top: -50px;
    color: $white !important;

    &:before {
      content: "\e708";
      font-size: 35px;
      font-family: Schwab-Icon-Font, serif !important;
      font-weight: bold;
    }

    &:hover {
      background: transparent !important;
    }
  }

  .sdps-modal__header {
    h2 {
      font-weight: bold;
      font-family: $font-family-regular;
      font-size: $font-normal;
      color: $black;
    }
  }

}

.sdps-modal__dialog--large .sdps-modal__content {
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 1s;
  animation-name: animatetop;
  animation-duration: 1s;
}

@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}


.modal-container {
  .sdps-input {
    padding: 0 8px 0 27px;
    @include border-radius(0px);
    height: 42px;
    text-indent: 10px;
  }
  .sdps-input:focus-visible {
    outline: none !important;
  }
}

.client-selection-indicator {
  @include border-radius(30px);
  padding: 4px 12px;
  color: $white;
  background: $subnav-active;

  &.dbs {
    background: $button-border;
  }

  &.rbs {
    background: $textarea-label-color;
  }

  &.sps {
    background: $subnav-active;
  }

  &.external-access {
    background: $client-name-indicator;
  }
}

.custom-table-filter input[disabled] {
  background: #d1d1d1 !important;
  opacity: 1;
  border: 1px solid #d1d1d1;
}

.p-progressbar {
  width: 75% !important;
  font-size: 12px !important;

  &.p-progressbar-determinate {
    .p-progressbar-value {
      //width: 20% !important;
      //text-align: center;
    }

    .p-progressbar-label {
      display: block !important;
      margin-top: 0;
      color: #333333;
      font-size: 14px !important;
    }
  }
}

.custom-progressbar {
  .p-progressbar .p-progressbar-value {
    width: 20% !important;
    text-align: center;
    background: #dee2e6;
  }
}
sdps-progress-indicator {
  .sdps-progress-indicator {
    position: relative;
    .sdps-progress-indicator__track {
      min-width: 5.5rem;
      height: 1.50rem;
      background-color: #eaeaea;
      .sdps-progress-indicator__fill {
        color: $iconColor;
      }
    }
    .sdps-progress-indicator__helper-text {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      color: $subnav-heading;
    }
  } 
}

.sdps-button{
  &:focus{
  outline: 1px solid #000;
  height: 60px;
  }
  }

.sdps-messaging__icon {
  padding: 0;
}

.sdps-modal__header {
  padding: 0 !important;

  .sdps-modal__title {
    padding-top: 14px !important;
    padding-bottom: 14px !important;
    font-weight: normal;
  }

  .sdps-modal__close {
    padding-top: 20px !important;
    padding-bottom: 14px !important;
    &:hover {
      height: 60px;
    }
  }
}

.sdps-modal__footer {
  padding-top: 14px !important;
  padding-bottom: 14px !important;
  display: flex;
  justify-content: center;
}

.p-inputtext, .p-inputwrapper {
   &:focus, .p-component.p-focus {
    box-shadow: none !important;
   }
 }

 .p-multiselect-panel .p-multiselect-item {
    display: block;

  .p-multiselect-items:focus {
    box-shadow: none;
  }

  .p-highlight {
    border: none;
  }
}

.p-checkbox .p-checkbox-box {
  .p-checkbox-icon {
    font-weight: bold;
  }
}

.p-multiselect-panel {
  .p-multiselect-items .p-multiselect-item:focus {
    box-shadow: none;
  }

  .p-multiselect-filter-container {
    width: 70%;
    position: inherit;
  }
}

.p-multiselect-panel .p-multiselect-header {
  display: block;
  .p-checkbox .p-checkbox-box {
    position: absolute;
    top: 50%;
  }
}

.emtr-dropdown,
.employee-dropdown,
.p-multiselect {
  .pi-chevron-down:before {
    content: "\e60a";
    font-family: "Schwab-Icon-Font", sans-serif !important;
    font-size: 20px !important;
    color: $dropdown-icon-color;
  }
}

.p-scroller {
  text-align: left;
  min-height: 35px;
 }
 
 .p-treetable, .p-datatable {
  .p-sortable-column .p-sortable-column-icon {
  display: inline;
}
}

.disabled-grey {
  background-color: $disabled-color !important;
  cursor:not-allowed !important;
  opacity: 1;
  .p-inputtext {
      background-color: $disabled-color !important;
  }
}

.preset-rule-tab {
  .info-icon {
      .sdps-icon {
        height: 20px;
        width: 20px;
    }
  } 
}

.account-popup-content-list {
  list-style-position: outside;
  padding-left: 35px;
  li {
    text-indent: -5px;
  }
}